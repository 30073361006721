export const NO_DELIVERY = 'Доставки нет'

export const DELIVERY_TYPES = {
    COURIER: 'courier',
    PICKUP: 'pickup',
}

export const PAYMENT_TYPES = {
    COURIER_CASH: 'courier_cash',
    COURIER_CARD: 'courier_card',
    ONLINE: 'online',
}

export const TOAST = {
    DURATION: 5000,
    TYPE_ERROR: 'error',
}
