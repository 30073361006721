import Vue from 'vue'
import { TOAST } from '../constants/index'

const showToast = (message, type) => {
    Vue.toasted.show(
        message,
        {
            duration: TOAST.DURATION,
            containerClass: 'toasted-outer',
            className: 'toasted-inner',
            type: type,
        },
    )
}

export default showToast
